import React from "react";
import tw from "tailwind-styled-components";
import { Link } from "react-router-dom";

const BookingSummaryComp = ({ data }) => (
  <BookingSummary>
    <Title>Courses Summary</Title>
    <BookingGrid>
      <Link to="/bookings">
        <TotalCourseBoxComp total={data?.data.totalCourse} />
      </Link>

      <Link to="/bookings/morning">
        <TotalStudentBoxComp total={data?.data.totalStudent} />
      </Link>

      <Link to="/bookings/afternoon">
        <TodaysStudentBoxComp total={data?.data.todayStudents} />
      </Link>

    </BookingGrid>
  </BookingSummary>
);

const TotalCourseBoxComp = ({ total }) => (
  <TotalBookingBox>
    <BookingTitle>Total Courses</BookingTitle>
    {/* <BookingSubTitle>
      We have large number of courses to provide you what you need
    </BookingSubTitle> */}
    <BookingCount>{total}</BookingCount> 
  </TotalBookingBox>
);

const TotalStudentBoxComp = ({ total }) => (
  <MorningBookingBox>
    <BookingTitle>Total Student Queries</BookingTitle>
    {/* <BookingSubTitle>
      Courses which are loved by millions of students
    </BookingSubTitle> */}
    <BookingCount>{total}</BookingCount> 
  </MorningBookingBox>
);

const TodaysStudentBoxComp = ({ total }) => (
  <AfternoonBookingBox>
    <BookingTitle>Today's Student Queries</BookingTitle>
    {/* <BookingSubTitle>
      Our latest courses to cover new & trending technologies
    </BookingSubTitle> */}
    <BookingCount>{total}</BookingCount> 
  </AfternoonBookingBox>
);

const EveningBookingBoxComp = ({ evening }) => (
  <EveningBookingBox>
    <BookingTitle>Updated Courses</BookingTitle>
    <BookingSubTitle>
      Courses which are upated with latest technologies
    </BookingSubTitle>
    <BookingCount>{evening}</BookingCount>
  </EveningBookingBox>
);

const BookingSummary = tw.div` md:border md:rounded-md md:shadow-md md:p-8 lg:p-10 `;
const Title = tw.h1`text-xl  text-gray-700 font-medium`;
const SubTitle = tw.h4`text-sm text-gray-500 mt-1.5`;

const BookingGrid = tw.div`grid grid-cols-2 md:grid-cols-3 mt-5 gap-6 sm:gap-16 lg:gap-20 text-center`;
const TotalBookingBox = tw.div` w-full h-36 md:h-40 grid place-content-center bg-green-200 rounded cursor-pointer transform transiton duration-200 hover:shadow-md hover:scale-105 `;
const MorningBookingBox = tw.div` w-full h-36 md:h-40 grid place-content-center bg-blue-200 rounded cursor-pointer transform transiton duration-200 hover:shadow-md hover:scale-105 `;
const AfternoonBookingBox = tw.div` w-full h-36 md:h-40 grid place-content-center bg-yellow-200 rounded cursor-pointer transform transiton duration-200 hover:shadow-md hover:scale-105 `;
const EveningBookingBox = tw.div` w-full h-36 md:h-40 grid place-content-center bg-purple-200 rounded cursor-pointer transform transiton duration-200 hover:shadow-md hover:scale-105 `;
const BookingTitle = tw.h3`text-gray-700 text-sm sm:text-base`;
const BookingSubTitle = tw.h3` mx-3  text-gray-500 text-xs mt-1 `;
const BookingCount = tw.p`font-medium text-3xl sm:text-4xl  text-gray-700 mt-2`;

export default BookingSummaryComp;
