import React, { useState } from "react";
import Model from "../Model";
import { SubmitBtn, PreviewBtn } from "../Styles/InputStyles";
import tw from "tailwind-styled-components";
import { Link } from "react-router-dom";
import Loading from "../Loading";
import Dropzone from "../Dropzone";
import Images from "../../Images";

const UploadModel = ({
  setOpenUploadModel,
  uploadExcelFile,
  uploadLoading,
}) => {
  const [uploadFile, setUploadFile] = useState("");

  return (
    <Model width={"w-11/12 max-w-lg"} setOpenModel={setOpenUploadModel}>
      {uploadLoading && <Loading />}
      {!uploadLoading && (
        <>
          <Title> Upload Excel File </Title>

          <Dropzone componentFor="admin" setUploadFile={setUploadFile} />

          <SampleFile href={Images.Excel}> Download Sample File </SampleFile>

          <div className="w-full flex items-center justify-center space-x-10 mt-8">
            <SubmitBtn
              type="submit"
              onClick={() => uploadExcelFile(uploadFile)}
            >
              Upload File
            </SubmitBtn>
          </div>
        </>
      )}
    </Model>
  );
};

const Title = tw.h2` text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const SampleFile = tw.a` w-full pt-3 text-blue-500 underline text-sm text-center`;

export default UploadModel;
