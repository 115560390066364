import React from "react";
import tw from "tailwind-styled-components";
import { Link, useParams } from "react-router-dom";
import Images from "../Images";
import Table from "../Components/BookingList/Table";
import { useQuery } from "react-query";
import axios from "axios";
import Config from "../Config";
import Loading from "../Components/Loading";
import {
  Page,
  BoxContainer,
  BoxTitle,
  ErrorText,
} from "../Components/Styles/PageStyles";
import NotFoundModel from "../Components/NotFoundModel";

const BookingList = () => {
  const { bookingType } = useParams();

  const fetchFunction = async () =>
    await axios.get(
      `${Config.BookingListApi}/${bookingType || "All"}`,
      Config.AxiosConfig
    );

  const { isLoading, error, data } = useQuery(
    `BookingListApi-${bookingType || "All"}`,
    fetchFunction
  );

  return (
    <Page>
      <BoxContainer>
        <Link to="/">
          <BoxTitle>
            <img src={Images.Arrow} alt="arrow" className="w-2" />
            <p className="capitalize">{bookingType || "All"} Booking Detail</p>
          </BoxTitle>
        </Link>

        {!isLoading && (
          <TableWrapper>
            <Table ApiData={error ? [] : data?.data} />
          </TableWrapper>
        )}

        {isLoading && <Loading />}
        {error && !isLoading && <NotFoundModel />}
      </BoxContainer>
    </Page>
  );
};

// TIME - INTERVALS
{
  /* <SingleTableComp time="8 am to 8:15 am" /> */
}

// const SingleTableComp = ({ time }) => (
//   <SingleTable>
//     <TimeInterval time={time} />
//     <Table />
//   </SingleTable>
// );

// const TimeInterval = ({ time }) => (
//   <div className=" text-sm  inline-block text-gray-600 bg-blue-50 rounded-t p-2 px-4">
//     Booking Timings : <span className="font-normal ">{time}</span>
//   </div>
// );

const TableWrapper = tw.div`
my-10`;

const SingleTable = tw.div` mt-10`;

export default BookingList;
