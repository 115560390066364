import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import Images from "../Images";
import { Link } from "react-router-dom";
import NavFloatingMenu from "./NavFloatingMenu";
import { useDispatch } from "react-redux";
import { Remove_User } from "../Redux/actions";

const Navbar = () => {
  const [isNavActive, setIsNavActive] = useState(false);
  const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);
  const [isMobileUser, setIsMobileUser] = useState(false);

  const dispatch = useDispatch();

  const logout = () => dispatch(Remove_User());

  useEffect(() => {
    const isMobile = window.innerWidth < 768;
    setIsMobileUser(isMobile);
  }, [window.innerWidth]);

  const toggleNavbar = () => setIsNavActive(!isNavActive);

  return (
    <Nav>
      <Container $isNavActive={isNavActive}>
        <Link to="/" className="z-10">
          <Logo>
            <LogoImg src={Images.Logo} alt="Leaning Beam logo" />
            <LogoText>Learning Beam Academy</LogoText>
          </Logo>
        </Link>
        <MenuIconComp toggleNavbar={toggleNavbar} />
        <NavLinksComp
          isNavActive={isNavActive}
          isUserMenuVisible={isUserMenuVisible}
          setIsUserMenuVisible={setIsUserMenuVisible}
          isMobileUser={isMobileUser}
          logout={logout}
        />
      </Container>
    </Nav>
  );
};

const NavLinksComp = ({
  isNavActive,
  setIsUserMenuVisible,
  isUserMenuVisible,
  isMobileUser,
  logout,
}) => (
  <NavLinksWrapper $isNavActive={isNavActive}>
    {/* <Link to="/search" className="h-full">
      <NavLink>
        <img src={Images.SearchIcon} alt="search icon" />
        <p>Search For Student</p>
      </NavLink>
    </Link> */}

    <Link to="/all-courses" className="h-full">
      <NavLink>
        <img src={Images.List} alt="List icon" />
        <p>All Courses List</p>
      </NavLink>
    </Link>

     <Link to="/students-queries" className="h-full">
      <NavLink>
        <img src={Images.List} alt="List icon" />
        <p>Students Queries</p>
      </NavLink>
    </Link>

    {/* <Link to="/help" className="h-full">
      <NavLink>
        <p>Need Help</p>
      </NavLink>
    </Link> */}

    {!isMobileUser && (
      <DesktopMenu
        setIsUserMenuVisible={setIsUserMenuVisible}
        isUserMenuVisible={isUserMenuVisible}
        logout={logout}
      />
    )}

    {isMobileUser && <MobileMenu logout={logout} />}
  </NavLinksWrapper>
);

const DesktopMenu = ({ setIsUserMenuVisible, isUserMenuVisible, logout }) => (
  <NavLink
    $last={true}
    onMouseEnter={() => setIsUserMenuVisible(true)}
    onMouseLeave={() => setIsUserMenuVisible(false)}
    onClick={() => setIsUserMenuVisible(!isUserMenuVisible)}
  >
    <UserImage src={Images.UserImage} alt="user image" />
    {/* <p className="md:hidden">My Profile</p> */}
    {isUserMenuVisible && (
      <NavFloatingMenu
        setIsUserMenuVisible={setIsUserMenuVisible}
        logout={logout}
      />
    )}
  </NavLink>
);

const MobileMenu = ({ logout }) => (
  <>
    {/* <NavLink title="My Profile">
      <UserImage src={Images.NurseImage} alt="nurse image" />
      <p>My Profile</p>
    </NavLink> */}

    <NavLink $last={true} title="My Profile">
      <Logout onClick={logout}>Logout</Logout>
    </NavLink>
  </>
);

const MenuIconComp = ({ toggleNavbar }) => (
  <MenuIcon onClick={toggleNavbar}>
    <div className="bar one"></div>
    <div className="bar two"></div>
    <div className="bar three"></div>
  </MenuIcon>
);

const Nav = tw.nav`z-40 bg-white w-full border-b navbar fixed top-0 right-0 left-0`;

const MenuIcon = tw.div`cursor-pointer z-10 md:hidden`;

const Container = tw.div` 
${(p) => (p.$isNavActive ? "nav-active" : "")}
Container flex items-center justify-between`;

const Logo = tw.div`flex items-center space-x-2`;

const LogoImg = tw.img`w-14 z-10`;

const LogoText = tw.h2` text-gray-700 font-medium whitespace-nowrap text-xl `;

const NavLinksWrapper = tw.ul`
${(p) => (p.$isNavActive ? "translate-y-0" : "-translate-y-full")}
fixed  shadow-md top-0 right-0 left-0 flex flex-col bg-white pt-20 transition duration-200 pb-5  md:relative  md:translate-y-0 md:shadow-none md:flex-row md:space-x-6  md:bg-transparent md:p-0   md:items-center w-full md:justify-end md:h-full`;

const NavLink = tw.li` 
${(p) => (p.$last ? "" : "border-b")}
text-sm text-gray-500 hover:text-gray-800 relative hover:bg-gray-100 flex  space-x-1.5 items-center cursor-pointer w-full   py-3 nav-links-padding  md:hover:bg-transparent md:p-0 md:bg-tranparent  md:border-transparent  md:border-b-2 hover:border-gray-400  whitespace-nowrap md:w-auto md:h-full `;

const UserImage = tw.img`
w-6 h-6  md:w-8 md:h-8 rounded-full overflow-hidden bg-gray-100
`;

const UserMenuWrapper = tw.div`
 p-2 relative `;

const Logout = tw.p` 
text-red-500 text-sm cursor-pointer`;

export default Navbar;
