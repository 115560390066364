import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import { Link } from "react-router-dom";
import moment from "moment";

const AdminTable = ({ ApiData, deleteDeviceMutate }) => {
  const [data, setData] = useState([
    {
      deviceName: "",
      extractionTime: "",
      processingTime: "",
      drops: "",
      action: "",
    },
  ]);

  const ActionBtns = (item) => {
    return (
      <ActionBtnWrapper>
        <Delete
          title="Delete device"
          onClick={() => deleteDeviceMutate(item.deviceId)}
        >
          <img src={Images.Delete} alt="delete icon" className="w-4" />
        </Delete>
      </ActionBtnWrapper>
    );
  };

  useEffect(() => {
    if (ApiData) {
      const tempData = ApiData.map((item) => ({
        deviceName: item.deviceName,
        extractionTime: item.extractionTime,
        processingTime: item.processingTime,
        drops: item.drops,
        action: ActionBtns(item),
      }));

      setData(tempData);
    }
  }, [ApiData]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Device Name",
        accessor: "deviceName",
      },
      {
        Header: "Extraction Time",
        accessor: "extractionTime",
      },
      {
        Header: "Processing Time",
        accessor: "processingTime",
      },
      {
        Header: "Drops",
        accessor: "drops",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <CustomTable {...getTableProps()}>
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Th {...column.getHeaderProps()}>{column.render("Header")}</Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>;
              })}
            </Tr>
          );
        })}
      </Tbody>
    </CustomTable>
  );
};

const UserImageWrapper = tw.div` flex items-center space-x-2`;
const UserImage = tw.img`w-5 h-5 rounded-full overflow-hidden`;

const ActionBtnWrapper = tw.div`flex items-center space-x-3`;
const AddBtn = tw.button`grid place-items-center bg-blue-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const EditBtn = tw.button`grid place-items-center bg-yellow-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const Delete = tw.button`grid place-items-center  w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;

const CustomTable = tw.table` w-full`;
const Thead = tw.thead`border-b`;
const Tbody = tw.tbody`border-b`;
const Tr = tw.tr`border-b `;
const Th = tw.th`text-left text-sm p-2 font-medium bg-blue-50 text-gray-600`;
const Td = tw.td`p-2 text-xs`;
const BookingBtn = tw.button` px-8 py-1 shadow bg-green-100 text-green-700 rounded cursor-pointer`;

export default AdminTable;
