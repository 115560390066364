import React, { useEffect, useState } from "react";
import { useTable, useSortBy } from "react-table";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { Save_Customer_details } from "../../Redux/actions";
import { useDispatch } from "react-redux";
import Loading from "../Loading";

const Table = ({ ApiData, sendEmailReminder }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const userImage = () => (
  //   <UserImageWrapper>
  //     <UserImage src={Images.userImage} alt="user-image" />
  //     <p>Andrew</p>
  //   </UserImageWrapper>
  // );

  const goToCustomerForm = (item) => {
    dispatch(Save_Customer_details(item));
    navigate(`/customer-form/${item.customerId}/${item.id}`);
  };

  const goToFilledCustomerForm = (item) => {
    navigate(`/edit-customer-form/${item.customerId}/${item.id}`);
  };

  const goToJoinMeeting = (item) => {
    window.open(item.joinLink, "_blank");
  };

  const [data, setData] = useState([
    {
      name: "",
      email: "",
      sessDuration: "",
      startTime: "",
      sessionDate: "",
      action: "",
    },
  ]);

  const ActionBtns = (item) => {
    // const sessionDate = moment(item.sessionEndDate).format("DD-MM-YYYY");
    // const today = moment().format("DD-MM-YYYY");
    // const sessionTime = moment(item.sessionEndTime, "HH:mm:ss").format(
    //   "HH:mm:SS"
    // );
    // const now = moment().format("HH:mm:SS");

    // let isSessionExpired = false;

    // if (sessionDate < today) isSessionExpired = true;
    // else if (sessionDate === today && sessionTime < now)
    //   isSessionExpired = true;
    // else if (sessionDate > today) isSessionExpired = false;

    return (
      <ActionBtnWrapper>
        {item.status === 0 ? (
          <>
            <AddBtn title="Join Meeting" onClick={() => goToJoinMeeting(item)}>
              <img src={Images.Join} alt="add icon" className="w-4" />
            </AddBtn>

            <CreateBtn
              title="Create Report"
              onClick={() => goToCustomerForm(item)}
            >
              <img src={Images.Add} alt="create icon" />
            </CreateBtn>

            <EmailBtn
              title="Send Reminder Email"
              onClick={() => sendEmailReminder(item.id)}
            >
              <img
                src={Images.Reminder}
                alt="Reminder icon"
                className="w-3.5"
              />
            </EmailBtn>
          </>
        ) : (
          <Link to={`/customer-reports/${item.id}`}>
            <ViewReports title="View Reports">
              <img src={Images.Eye} alt="edit icon" />
            </ViewReports>
          </Link>
        )}
      </ActionBtnWrapper>
    );
  };

  useEffect(() => {
    if (ApiData) {
      const tempData = ApiData.map((item) => ({
        name: item.fullName,
        email: item.email,
        sessionDate: moment(item.sessionDate).format("DD-MM-YYYY"),
        sessDuration: item.sessDuration,
        startTime: moment(item.sessionTime, "HH:mm:ss").format("hh:mm A"),
        action: ActionBtns(item),
      }));

      setData(tempData);
    }
  }, [ApiData]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Session Date",
        accessor: "sessionDate",
      },
      {
        Header: "Start Time",
        accessor: "startTime",
      },
      {
        Header: (
          <p>
            {/* Session Duration <small>(in minutes)</small>{" "} */}
            Duration
          </p>
        ),
        accessor: "sessDuration",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        data,
        columns,
      },
      useSortBy
    );

  return (
    <CustomTable {...getTableProps()}>
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render("Header")}
                <span>
                  {column.isSorted ? (
                    <img
                      src={Images.Arrow}
                      alt="down arrow"
                      className={`${
                        column.isSortedDesc ? "-rotate-90" : "rotate-90"
                      } w-1.5 inline-block ml-1.5`}
                    />
                  ) : (
                    ""
                  )}
                </span>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>;
              })}
            </Tr>
          );
        })}
      </Tbody>
    </CustomTable>
  );
};

const UserImageWrapper = tw.div` flex items-center space-x-2`;
const UserImage = tw.img`w-5 h-5 rounded-full overflow-hidden`;

const ActionBtnWrapper = tw.div`flex items-center space-x-3`;
const AddBtn = tw.button`grid place-items-center bg-blue-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const CreateBtn = tw.button`grid place-items-center bg-yellow-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const ViewReports = tw.button`grid place-items-center bg-orange-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const CallBtn = tw.button`grid place-items-center bg-green-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const EmailBtn = tw.button`grid place-items-center bg-green-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;

const CustomTable = tw.table` w-full`;
const Thead = tw.thead`border-b`;
const Tbody = tw.tbody`border-b`;
const Tr = tw.tr`border-b `;
const Th = tw.th`text-left text-sm p-2 font-medium bg-blue-50 text-gray-600  `;
const Td = tw.td`p-2 text-xs`;

export default Table;
