import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Images from "../Images";
import axios from "axios";
import Config from "../Config";
import Loading from "../Components/Loading";
import { useMutation, useQuery } from "react-query";
import Alert from "../Components/Alert";
import {
  Page,
  BoxContainer,
  BoxTitle,
  ErrorText,
} from "../Components/Styles/PageStyles";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import {
  HorizontalInputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
} from "../Components/Styles/InputStyles";
import * as Yup from "yup";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const AddChapter = () => {
  const navigate = useNavigate();
  const [alert, setAlert] = useState({ show: false, color: "", msg: "" });
  const clearAlert = () =>
    setTimeout(() => setAlert({ show: false, color: "", msg: "" }), 10000);

  const { id } = useParams();
  const [chapterDetails, setChapterDetails] = useState([]);

  //--------------- Get Course Detail -----------------

  const getChapterFunction = async (body) =>
    await axios.post(Config.GetChapters, body, Config.AxiosConfig);

  const onGetChapterSuccess = (data) => {
    console.log("success", data);
    if (data.data.length > 0) { 
      setChapterDetails(data.data);
    } 
  };

  const onGetChapterError = (data) => {
    console.log("error", data.response);
  };

  const { isLoading: getChapterLoading, mutate: getChapterMutate } = useMutation(
    getChapterFunction,
    {
      onSuccess: onGetChapterSuccess,
      onError: onGetChapterError,
    }
  );

  useEffect(() => {
    const body = new FormData();
    body.append("courseCode", id);
    getChapterMutate(body);
  }, []);

  // ----------- Add Chapters-------------

  const addChapterFunction = async (body) =>
    await axios.post(
      Config.AddChapters,
      body,
      Config.AxiosConfig
    );

  const onAddChapterSuccess = (data) => {
    window.scrollTo({ top: 0 });
    console.log("success", data);
    setAlert({ show: true, color: "green", msg: data.data.msg });
    clearAlert();
  };
  const onAddChapterError = (data) => {
    window.scrollTo({ top: 0 });
    console.log("error", data.response);
    setAlert({ show: true, color: "red", msg: data.data.msg });
    clearAlert();
  };

  const { isLoading: addChapterDetailsLoading, mutate: addChapterMutate } =
    useMutation(addChapterFunction, {
      onSuccess: onAddChapterSuccess,
      onError: onAddChapterError,
    }); 

  
  const InitialValues = {
    numberOfChapters: chapterDetails?.length || "",
    chapters: chapterDetails || [],
  };

  const validationSchema = Yup.object().shape({
    numberOfChapters: Yup.string().required('Number of Chapters is required'),
    chapters: Yup.array().of(Yup.object().shape({
      title: Yup.string().required('Name is required'),
      duration: Yup.number("Please enter a valid price")
        .positive("Please enter a valid price")
        .required("This field is required"),
      })
    )
  });
  
  function onChangeChapters(e, field, values, setValues) {
        const chapters = [...values.chapters];
        const numberOfChapters = e.target.value || 0;
        const previousNumber = parseInt(field.value || '0');
        if (previousNumber < numberOfChapters) {
            for (let i = previousNumber; i < numberOfChapters; i++) {
                chapters.push({ title: '', duration: '' });
            }
        } else {
            for (let i = previousNumber; i >= numberOfChapters; i--) {
                chapters.splice(i, 1);
            }
        }
        setValues({ ...values, chapters });
        field.onChange(e);
    }


   const SubmitHandler = (values) => {
    console.log("submit", values);

    const body = new FormData();
    body.append("courseCode", id);
    body.append("chapters", JSON.stringify(values.chapters));
    addChapterMutate(body);
  };

  return (
    <Page>
      <BoxContainer>
        <BoxTitle onClick={() => navigate(-1)}>
          <img src={Images.Arrow} alt="arrow" className="w-2" />
          <p className="capitalize">Add Chapters</p>
        </BoxTitle>

        {alert.show && <Alert msg={alert.msg} color={alert.color || "red"} />}

        <Formik
          initialValues={InitialValues}
          onSubmit={SubmitHandler}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ errors, values, touched, setValues }) => (
          <Form className="max-w-xl mx-auto">

            <HorizontalInputGroup>
              <Label htmlFor="numberOfChapters">Number of Chapters</Label>
              <div>
                <FieldWrapper $select={true}>
                  <Field name="numberOfChapters" >
                    {({field}) => (
                      <Select
                        {...field}
                        className="w-full h-full pl-2 bg-transparent text-sm p-0  border-none"
                        onChange={e => onChangeChapters(e, field, values, setValues)}
                      >
                        <MenuItem value=""></MenuItem>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(i => 
                          <MenuItem key={i} value={i}>{i}</MenuItem>    
                        )}
                        
                      </Select>
                    )}
                  </Field>
                </FieldWrapper>
                <ErrorMessage name="numberOfChapters" component={ErrorMessageComp} />
              </div>
            </HorizontalInputGroup>

            <FieldArray name="chapters">
              {() => (values.chapters.map((ticket, i) => {                                        
                return (                              
                  <div key={i} className="list-group list-group-flush">                    
                    <div className="list-group-item">                     
                      <h5 className="card-title">Chapter {i + 1}</h5> 
                      <HorizontalInputGroup>
                        <Label htmlFor="title">Title*</Label>
                        <div>
                          <FieldWrapper>
                            <Field
                              name={`chapters.${i}.title`}
                              type="text"
                              id={`chapters.${i}.title`}
                              autoComplete="off"
                            />
                          </FieldWrapper>
                          <ErrorMessage name={`chapters.${i}.title`} component={ErrorMessageComp} />
                        </div>
                      </HorizontalInputGroup>

                      <HorizontalInputGroup>
                        <Label htmlFor={`chapters.${i}.duration`}>Duration*</Label>
                        <div>
                          <FieldWrapper>
                            <Field
                              name={`chapters.${i}.duration`}
                              type="number"
                              id={`chapters.${i}.duration`}
                              autoComplete="off"
                            />
                          </FieldWrapper>
                          <ErrorMessage name={`chapters.${i}.duration`} component={ErrorMessageComp} />
                        </div>
                      </HorizontalInputGroup>  
                    
                  </div>                   
                </div>                 
                );
              }))}            
            </FieldArray>
            


            <div className="w-full grid place-items-center mb-10">
              <SubmitBtn type="submit" disabled={addChapterDetailsLoading}>
                {(!addChapterDetailsLoading) && "Submit"}
                {(addChapterDetailsLoading ) && (
                  <Loading
                    color="brown"
                    width={20}
                    height={20}
                    noPadding={true}
                  />
                )}
              </SubmitBtn>
            </div>
            </Form>
            )}
        </Formik>
      </BoxContainer>
    </Page>
  );
};

const ErrorMessageComp = ({ children }) => <ErrorText>{children}</ErrorText>;

export default AddChapter;
