import React from "react";
import tw from "tailwind-styled-components";
import Images from "../Images";

const ErrorModel = () => {
  return (
    <Wrapper>
      <Figure>
        <img src={Images.ErrorImage} alt="error image" />
      </Figure>
      <Title>Network Error Occured</Title>
      <Para>Please try again after some time. We are working on it.</Para>
    </Wrapper>
  );
};

const Wrapper = tw.div`w-11/12 max-w-3xl mx-auto my-5  flex flex-col  `;
const Title = tw.h2`text-lg md:text-xl lg:text-2xl text-red-400 font-normal text-center`;
const Para = tw.p`text-xs md:text-sm text-gray-400 mt-0.5 font-light md:leading-relaxed text-center   `;
const Figure = tw.div`w-40 mx-auto mb-10`;

export default ErrorModel;
