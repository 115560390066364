import tw from "tailwind-styled-components";

export const Page = tw.section`py-24 md:pt-28`;
export const BoxContainer = tw.div`Container md:rounded-md md:border md:shadow-md md:p-8 `;
export const BoxTitle = tw.h1`text-xl  text-gray-700 font-medium inline-flex space-x-2 items-center hover:bg-gray-50 p-2 rounded-md cursor-pointer`;
export const BoxSubTitle = tw.h3`mt-1 ml-1 sm:mt-2 lg:mt-3   text-gray-500 text-sm lg:text-base `;
export const ErrorText = tw.p`my-1 text-red-600 text-xs `;

export const PrevBtn = tw.p`w-8  h-8 rounded-full cursor-pointer disabled:opacity-25 disabled:cursor-not-allowed text-yellow-700 text-sm  bg-yellow-100 grid place-items-center  inline-block`;

export const NextBtn = tw.p`w-8  h-8 rounded-full cursor-pointer disabled:opacity-25 disabled:cursor-not-allowed text-blue-700 text-sm  bg-blue-100 grid place-items-center  inline-block`;

export const PaginationWrapper = tw.div`flex mt-5 justify-end gap-3 items-center text-sm text-gray-600`;
