export const Save_Device_types = (data) => ({
  type: "Save_Device_types",
  payload: data,
});

export const Save_Customer_details = (data) => ({
  type: "Save_Customer_details",
  payload: data,
});

//Users

export const Save_User = (data) => ({
  type: "Save_User",
  payload: data,
});

export const Update_User = (data) => ({
  type: "Update_User",
  payload: data,
});

export const Remove_User = () => ({
  type: "Remove_User",
});
