import React, { useEffect, useState } from "react";
import {
  Page,
  BoxContainer,
  BoxTitle,
  BoxSubTitle,
  ErrorText,
} from "../Components/Styles/PageStyles";
import { useNavigate } from "react-router-dom";
import Images from "../Images";
import tw from "tailwind-styled-components";
import Config from "../Config";
import { useQuery } from "react-query";
import axios from "axios";
import SearchTable from "../Components/Search/SearchTable";
import Loading from "../Components/Loading";
import NotFoundModel from "../Components/NotFoundModel";
import SearchImage from "../Components/SVGs/SearchImage";

const Search = () => {
  const navigate = useNavigate();

  const [searchType, setSearchType] = useState("email");
  const [searchValue, setSearchValue] = useState("");

  const fetchFunction = async (type, value) => {
    if (value)
      return await axios.get(
        `${Config.SearchApi}/${type}/${value}`,
        Config.AxiosConfig
      );
    else return null;
  };

  const { isLoading, error, data, refetch, isRefetching } = useQuery(
    "searchData",
    () => fetchFunction(searchType, searchValue)
  );

  useEffect(() => {
    if (!searchValue) refetch();
  }, [searchValue]);

  const ChangeHandler = (e) => setSearchValue(e.target.value);

  const SubmitHandler = (e) => {
    e.preventDefault();

    refetch();
  };

  return (
    <Page>
      <BoxContainer>
        <BoxTitle onClick={() => navigate(-1)}>
          <img src={Images.Arrow} alt="arrow" className="w-2" />
          <p>Search Course</p>
        </BoxTitle>

        <Wrapper>
          <SearchInput onSubmit={SubmitHandler}>
            <input
              type="search"
              placeholder="Search by student's email or mobile number "
              autoFocus
              onChange={ChangeHandler}
              value={searchValue}
            />
            <SearchIcon type="submit">
              <img
                src={Images.SearchBlueIcon}
                alt="search icon"
                className="w-4"
              />
            </SearchIcon>
          </SearchInput>

          {(isLoading || isRefetching) && <Loading />}

          {error && !isLoading && !isRefetching && <NotFoundModel />}

          {!isLoading && !isRefetching && !error && !data && (
            <PlaceholderModalComp />
          )}

          {!isLoading && !error && !isRefetching && data && (
            <TableWrapper>
              <SearchTable ApiData={data?.data} />
            </TableWrapper>
          )}
        </Wrapper>
      </BoxContainer>
    </Page>
  );
};

const PlaceholderModalComp = () => (
  <div className="mt-10 md:mt-16">
    <Figure>
      <SearchImage />
    </Figure>
    <PlaceholderText>
      Please enter student's email / mobile number to search
    </PlaceholderText>
  </div>
);

const Wrapper = tw.div`
mt-10`;

const SearchInput = tw.form`w-11/12 mx-auto field-wrapper relative pl-2  rounded-md  bg-white  w-full   border border-gray-400 flex items-center overflow-hidden`;

const SearchIcon = tw.button`absolute -top-1 -right-1 -bottom-1 px-4 grid place-items-center bg-blue-100 cursor-pointer hover:bg-blue-200 transition duration-200`;

const TableWrapper = tw.h1`w-11/12 mx-auto mt-10`;

const PlaceholderText = tw.p`text-gray-400 text-sm md:text-base text-center`;
const Figure = tw.div`w-44  mx-auto mb-8`;

export default Search;
