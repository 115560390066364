//Development Server
// const baseURL = "http://3.138.202.75:3003";
//Production Server
//const baseURL = "http://3.138.202.75/PROJECTS/LearningBeam_Admin/api";
//const baseURL = "http://3.138.198.156/LearningBeam/lb_react/admin/admin-panel/build/api";
const baseURL = "/api";

const Config = {
  AxiosConfig: {
    headers: {
      authorization: "Bearer wqwqwqwqw",
    },
  },

  AddCourseApi: baseURL + "/addcourse",
  AllCoursesListApi: baseURL + "/getcourseslist",
  GetStudentQuery: baseURL + "/getStudentQuery",
  GetDashboardData: baseURL + "/getDashboardData",
  GetSingleCourseDetailApi: baseURL + "/get-single-Course-Detials",
  GetCourseDetail: baseURL + "/getCourseDetails",
  GetChapters: baseURL + "/getChapters",
  AddCourseDetails: baseURL + "/addCourseDetails",
  AddChapters: baseURL + "/addChapters",
  UpdateCourseDetails: baseURL + "/updateCourseDetails",
  UpdateSingleCourseDetailApi: baseURL + "/update-single-Course-Detials",
  DeleteCourse: baseURL + "/deleteCourse",
  UploadPdf: baseURL + "/uploadPdf",
};

export const PaginationLimit = 10;

export const hasPrivateAccess = (userId) => {
  if (
    userId === "ecdd2b5f-e272-45ae-a7b7-c245b5bf1402" ||
    userId === "e9ca6870-e80a-4df1-9b27-b6986fd6244a" ||
    userId === "deea753a-a3ef-4993-9610-3b760fa3f127"
  ) {
    return true;
  }

  return false;
};

export default Config;
