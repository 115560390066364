import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Images from "../Images";
import axios from "axios";
import Config from "../Config";
import Loading from "../Components/Loading";
import { useMutation, useQuery } from "react-query";
import Alert from "../Components/Alert";
import {
  Page,
  BoxContainer,
  BoxTitle,
  ErrorText,
} from "../Components/Styles/PageStyles";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  HorizontalInputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
} from "../Components/Styles/InputStyles";
import * as Yup from "yup";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const AddDetailCourse = () => {
  const navigate = useNavigate();
  const [alert, setAlert] = useState({ show: false, color: "", msg: "" });
  const clearAlert = () =>
    setTimeout(() => setAlert({ show: false, color: "", msg: "" }), 10000);

  const { id } = useParams();
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [courseDetails, setCourseDetails] = useState({});

  //--------------- Get Course Detail -----------------

  const getCourseFunction = async (body) =>
    await axios.post(Config.GetCourseDetail, body, Config.AxiosConfig);

  const onGetCourseSuccess = (data) => {
    console.log("success", data);
    if (data.data.length > 0) { 
      setIsDataAvailable(true);
      setCourseDetails(data.data[0]);
    } else {
      setIsDataAvailable(false);
    }
  };
  console.log("isDataAvailable", isDataAvailable);

  const onGetCourseError = (data) => {
    console.log("error", data.response);
  };

  const { isLoading: getCourseLoading, mutate: getCourseMutate } = useMutation(
    getCourseFunction,
    {
      onSuccess: onGetCourseSuccess,
      onError: onGetCourseError,
    }
  );

  useEffect(() => {
    const body = new FormData();
    body.append("courseCode", id);
    getCourseMutate(body);
  }, []);

  // ----------- Add Course Details-------------

  const addCourseFunction = async (body) =>
    await axios.post(
      Config.AddCourseDetails,
      body,
      Config.AxiosConfig
    );

  const onAddCourseSuccess = (data) => {
    window.scrollTo({ top: 0 });
    console.log("success", data);
    setAlert({ show: true, color: "green", msg: data.data.msg });
    clearAlert();
  };
  const onAddCourseError = (data) => {
    window.scrollTo({ top: 0 });
    console.log("error", data.response);
    setAlert({ show: true, color: "red", msg: data.data.msg });
    clearAlert();
  };

  const { isLoading: addCourseDetailsLoading, mutate: addCourseMutate } =
    useMutation(addCourseFunction, {
      onSuccess: onAddCourseSuccess,
      onError: onAddCourseError,
    }); 

  // ----------- Update/Edit course -------------

  const editCourseFunction = async (body) =>
    await axios.post(
      Config.UpdateCourseDetails,
      body,
      Config.AxiosConfig
    );

  const onEditCourseSuccess = (data) => {
    window.scrollTo({ top: 0 });
    console.log("success", data);
    setAlert({ show: true, color: "green", msg: data.data.msg });
    clearAlert();
  };
  const onEditCourseError = (data) => {
    window.scrollTo({ top: 0 });
    console.log("error", data.response);
    setAlert({ show: true, color: "red", msg: data.data.msg });
    clearAlert();
  };

  const { isLoading: editCourseDetailsLoading, mutate: editCourseMutate } =
    useMutation(editCourseFunction, {
      onSuccess: onEditCourseSuccess,
      onError: onEditCourseError,
    }); 

  const InitialValues = {
    courseTitle: courseDetails?.course_title || "",
    department: courseDetails?.department,
    creditValue: courseDetails?.credit_value,
    eligibility: courseDetails?.eligibility,
    prerequisite: courseDetails?.prerequisite,
    fee: courseDetails?.fee,
    internationalFee: courseDetails?.international_fee,
    duration: courseDetails?.duration,
    chapters: courseDetails?.chapters,
    description: courseDetails?.description,
  };

  const validationSchema = Yup.object().shape({
    courseTitle: Yup.string().required("This field is required"),
    department: Yup.string().required("This field is required"),
    creditValue: Yup.string().required("This field is required"),
    eligibility: Yup.string().required("This field is required"),
    prerequisite: Yup.string().required("This field is required"),
    fee: Yup.number("Please enter a valid price")
      .positive("Please enter a valid price")
      .required("This field is required"),
    internationalFee: Yup.number("Please enter a valid price")
      .positive("Please enter a valid price")
      .required("This field is required"),
    duration: Yup.string().required("This field is required"),
    description: Yup.string().required("This field is required"),
  });

  const SubmitHandler = (values) => {
    console.log("submit", values);

    const body = new FormData();
    body.append("courseCode", id);
    body.append("courseTitle", values.courseTitle);
    body.append("department", values.department);
    body.append("creditValue", values.creditValue);
    body.append("eligibility", values.eligibility);
    body.append("prerequisite", values.prerequisite);
    body.append("fee", values.fee);
    body.append("internationalFee", values.internationalFee);
    body.append("duration", values.duration);
    body.append("chapters", "");
    body.append("description", values.description);
    if (!isDataAvailable) {
      addCourseMutate(body);
    } else { 
      editCourseMutate(body);
    }
    
  };

  return (
    <Page>
      <BoxContainer>
        <BoxTitle onClick={() => navigate(-1)}>
          <img src={Images.Arrow} alt="arrow" className="w-2" />
          <p className="capitalize">Add Course Detail</p>
        </BoxTitle>

        {alert.show && <Alert msg={alert.msg} color={alert.color || "red"} />}

        <Formik
          initialValues={InitialValues}
          onSubmit={SubmitHandler}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          <Form className="max-w-xl mx-auto">

            <HorizontalInputGroup>
              <Label htmlFor="courseTitle">Course Title*</Label>
              <div>
                <FieldWrapper>
                  <Field
                    name="courseTitle"
                    type="text"
                    id="courseTitle"
                    autoComplete="off"
                  />
                </FieldWrapper>
                <ErrorMessage name="courseTitle" component={ErrorMessageComp} />
              </div>
            </HorizontalInputGroup>

            <HorizontalInputGroup>
              <Label htmlFor="creditValue">Credit Value*</Label>
              <div>
                <FieldWrapper>
                  <Field
                    name="creditValue"
                    type="number"
                    id="creditValue"
                    autoComplete="off"
                  />
                </FieldWrapper>
                <ErrorMessage name="creditValue" component={ErrorMessageComp} />
              </div>
            </HorizontalInputGroup>

            <HorizontalInputGroup>
              <Label htmlFor="department">Department*</Label>
              <div>
                <FieldWrapper>
                  <Field
                    name="department"
                    type="text"
                    id="department"
                    autoComplete="off"
                  />
                </FieldWrapper>
                <ErrorMessage name="department" component={ErrorMessageComp} />
              </div>
            </HorizontalInputGroup>

            <HorizontalInputGroup>
              <Label htmlFor="eligibility">Eligibility*</Label>
              <div>
                <FieldWrapper>
                  <Field
                    name="eligibility"
                    type="text"
                    id="eligibility"
                    autoComplete="off"
                  />
                </FieldWrapper>
                <ErrorMessage name="eligibility" component={ErrorMessageComp} />
              </div>
            </HorizontalInputGroup>

            <HorizontalInputGroup>
              <Label htmlFor="duration">Duration(in hrs)*</Label>
              <div>
                <FieldWrapper>
                  <Field
                    name="duration"
                    type="number"
                    id="duration"
                    autoComplete="off"
                  />
                </FieldWrapper>
                <ErrorMessage name="duration" component={ErrorMessageComp} />
              </div>
            </HorizontalInputGroup>

            <HorizontalInputGroup>
              <Label htmlFor="fee">Domestic Fee*</Label>
              <div>
                <FieldWrapper>
                  <Field
                    name="fee"
                    type="number"
                    id="fee"
                    autoComplete="off"
                  />
                </FieldWrapper>
                <ErrorMessage name="fee" component={ErrorMessageComp} />
              </div>
            </HorizontalInputGroup>

             <HorizontalInputGroup>
              <Label htmlFor="internationalFee">International Fee*</Label>
              <div>
                <FieldWrapper>
                  <Field
                    name="internationalFee"
                    type="number"
                    id="internationalFee"
                    autoComplete="off"
                  />
                </FieldWrapper>
                <ErrorMessage name="internationalFee" component={ErrorMessageComp} />
              </div>
            </HorizontalInputGroup>

            <HorizontalInputGroup>
              <Label htmlFor="prerequisite">Prerequisite*</Label>
              <div>
                <FieldWrapper>
                  <Field
                    name="prerequisite"
                    id="prerequisite"
                    autoComplete="off"
                    as="textarea"
                    rows="2"
                  />
                </FieldWrapper>
                <ErrorMessage name="prerequisite" component={ErrorMessageComp} />
              </div>
            </HorizontalInputGroup>

             <HorizontalInputGroup>
              <Label htmlFor="description">Description*<br/>(max: 150 Words)</Label>
              <div>
                <FieldWrapper>
                  <Field
                    name="description"
                    id="description"
                    autoComplete="off"
                    as="textarea"
                    rows="4"
                  />
                </FieldWrapper>
                <ErrorMessage name="description" component={ErrorMessageComp} />
              </div>
            </HorizontalInputGroup>

            <div className="w-full grid place-items-center mb-10">
              <SubmitBtn type="submit" disabled={addCourseDetailsLoading || editCourseDetailsLoading}>
                {(!addCourseDetailsLoading && !editCourseDetailsLoading) && "Add Details"}
                {(addCourseDetailsLoading || editCourseDetailsLoading) && (
                  <Loading
                    color="brown"
                    width={20}
                    height={20}
                    noPadding={true}
                  />
                )}
              </SubmitBtn>
            </div>
          </Form>
        </Formik>
      </BoxContainer>
    </Page>
  );
};

const ErrorMessageComp = ({ children }) => <ErrorText>{children}</ErrorText>;

export default AddDetailCourse;
