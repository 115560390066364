import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Images from "../Images";
import axios from "axios";
import Config from "../Config";
import Loading from "../Components/Loading";
import { useMutation, useQuery } from "react-query";
import Alert from "../Components/Alert";
import {
  Page,
  BoxContainer,
  BoxTitle,
  ErrorText,
} from "../Components/Styles/PageStyles";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  HorizontalInputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
} from "../Components/Styles/InputStyles";
import * as Yup from "yup";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const AddCourse = () => {
  const navigate = useNavigate();
  const [alert, setAlert] = useState({ show: false, color: "", msg: "" });
  const clearAlert = () =>
    setTimeout(() => setAlert({ show: false, color: "", msg: "" }), 10000);

  const { id } = useParams();

  const [courseDetails, setCourseDetails] = useState({});

  //--------------- Get Course Detail -----------------

  const getCourseFunction = async (body) =>
    await axios.post(Config.GetSingleCourseDetailApi, body, Config.AxiosConfig);

  const onGetCourseSuccess = (data) => {
    console.log("success", data);
    setCourseDetails(data.data[0]);
  };
  const onGetCourseError = (data) => {
    console.log("error", data.response);
  };

  const { isLoading: getCourseLoading, mutate: getCourseMutate } = useMutation(
    getCourseFunction,
    {
      onSuccess: onGetCourseSuccess,
      onError: onGetCourseError,
    }
  );

  useEffect(() => {
    const body = new FormData();
    body.append("id", id);
    getCourseMutate(body);
  }, []);

  // ----------- Update/Edit course -------------

  const editCourseFunction = async (body) =>
    await axios.post(
      Config.UpdateSingleCourseDetailApi,
      body,
      Config.AxiosConfig
    );

  const onEditCourseSuccess = (data) => {
    window.scrollTo({ top: 0 });
    console.log("success", data);
    setAlert({ show: true, color: "green", msg: data.data.msg });
    clearAlert();
  };
  const onEditCourseError = (data) => {
    window.scrollTo({ top: 0 });
    console.log("error", data.response);
    setAlert({ show: true, color: "red", msg: data.data.msg });
    clearAlert();
  };

  const { isLoading: editCourseLoading, mutate: editCourseMutate } =
    useMutation(editCourseFunction, {
      onSuccess: onEditCourseSuccess,
      onError: onEditCourseError,
    });

  const InitialValues = {
    grade: courseDetails?.grade || "",
    courseName: courseDetails?.courseName,
    courseCode: courseDetails?.courseCode,
    courseDesc: courseDetails?.courseDesc,
    price: courseDetails?.fee,
    id: courseDetails?.id,
  };

  const validationSchema = Yup.object().shape({
    grade: Yup.string().required("This field is required"),
    courseName: Yup.string().required("This field is required"),
    courseCode: Yup.string().required("This field is required"),
    courseDesc: Yup.string().required("This field is required"),
    price: Yup.number("Please enter a valid price")
      .positive("Please enter a valid price")
      .required("This field is required"),
  });

  const SubmitHandler = (values) => {
    console.log("submit", values);

    const body = new FormData();
    body.append("grade", values.grade);
    body.append("courseName", values.courseName);
    body.append("courseCode", values.courseCode);
    body.append("courseDesc", values.courseDesc);
    body.append("price", values.price);
    body.append("id", values.id);
    editCourseMutate(body);
  };

  return (
    <Page>
      <BoxContainer>
        <BoxTitle onClick={() => navigate(-1)}>
          <img src={Images.Arrow} alt="arrow" className="w-2" />
          <p className="capitalize">Edit Course</p>
        </BoxTitle>

        {alert.show && <Alert msg={alert.msg} color={alert.color || "red"} />}

        <Formik
          initialValues={InitialValues}
          onSubmit={SubmitHandler}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          <Form className="max-w-xl mx-auto">
            <HorizontalInputGroup>
              <Label htmlFor="grade">Grade*</Label>
              <div>
                <FieldWrapper $select={true}>
                  <Field name="grade" autoComplete="off">
                    {(props) => (
                      <Select
                        {...props.field}
                        autoComplete="off"
                        className="w-full h-full pl-2 bg-transparent text-sm p-0  border-none"
                        value={props.form.values.grade}
                      >
                        {console.log("prop", props.form.values.grade)}
                        <MenuItem value="" disabled>
                          Select-Grade
                        </MenuItem>
                        <MenuItem value="9">Grade 9</MenuItem>
                        <MenuItem value="10">Grade 10</MenuItem>
                        <MenuItem value="11">Grade 11</MenuItem>
                        <MenuItem value="12">Grade 12</MenuItem>
                      </Select>
                    )}
                  </Field>
                </FieldWrapper>
                <ErrorMessage name="grade" component={ErrorMessageComp} />
              </div>
            </HorizontalInputGroup>

            <HorizontalInputGroup>
              <Label htmlFor="courseName">Course Name*</Label>
              <div>
                <FieldWrapper>
                  <Field
                    name="courseName"
                    type="text"
                    id="courseName"
                    autoComplete="off"
                    maxLength="25"

                  />
                </FieldWrapper>
                <ErrorMessage name="courseName" component={ErrorMessageComp} />
              </div>
            </HorizontalInputGroup>

            <HorizontalInputGroup>
              <Label htmlFor="courseCode">Course Code*</Label>
              <div>
                <FieldWrapper>
                  <Field
                    name="courseCode"
                    type="text"
                    id="courseCode"
                    autoComplete="off"
                    maxLength="7"

                  />
                </FieldWrapper>
                <ErrorMessage name="courseCode" component={ErrorMessageComp} />
              </div>
            </HorizontalInputGroup>

            <HorizontalInputGroup>
              <Label htmlFor="price">Fee*</Label>
              <div>
                <FieldWrapper>
                  <Field
                    name="price"
                    type="number"
                    id="price"
                    autoComplete="off"
                  />
                </FieldWrapper>
                <ErrorMessage name="price" component={ErrorMessageComp} />
              </div>
            </HorizontalInputGroup>

            <HorizontalInputGroup>
              <Label htmlFor="courseDesc">Description*</Label>
              <div>
                <FieldWrapper>
                  <Field
                    name="courseDesc"
                    id="courseDesc"
                    autoComplete="off"
                    as="textarea"
                    rows="4"
                    maxLength="25"
                  />
                </FieldWrapper>
                <ErrorMessage name="courseDesc" component={ErrorMessageComp} />
              </div>
            </HorizontalInputGroup>

            <div className="w-full grid place-items-center mb-10">
              <SubmitBtn type="submit" disabled={editCourseLoading}>
                {!editCourseLoading && "Edit Course"}
                {editCourseLoading && (
                  <Loading
                    color="brown"
                    width={20}
                    height={20}
                    noPadding={true}
                  />
                )}
              </SubmitBtn>
            </div>
          </Form>
        </Formik>
      </BoxContainer>
    </Page>
  );
};

const ErrorMessageComp = ({ children }) => <ErrorText>{children}</ErrorText>;

export default AddCourse;
