import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import { Link, useNavigate } from "react-router-dom";
import Images from "../Images";
import Table from "../Components/AllCoursesList/Table";
import { useQuery } from "react-query";
import axios from "axios";
import Config from "../Config";
import Loading from "../Components/Loading";
import { useMutation } from "react-query";
import Alert from "../Components/Alert";
import {
  Page,
  BoxContainer,
  BoxTitle,
  ErrorText,
} from "../Components/Styles/PageStyles";
import NotFoundModel from "../Components/NotFoundModel";
import { Formik, Form, Field } from "formik";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SmallBtn,
} from "../Components/Styles/InputStyles";
import UploadModel from '../Components/AllCoursesList/UploadModel';

const AllCoursesList = () => {
  const navigate = useNavigate();

  const [alert, setAlert] = useState({ show: false, color: "", msg: "" });

  const clearAlert = () =>
    setTimeout(() => setAlert({ show: false, color: "", msg: "" }), 10000);

  const fetchFunction = async () => await axios.get(Config.AllCoursesListApi);
  const [openUploadModel, setOpenUploadModel] = useState(false);
  const {
    isLoading: InitialLoading,
    error,
    data,
    refetch,
  } = useQuery("AllCoursesList", fetchFunction);

  const [courseCode, setCourseCode] = useState(false);

  const openUploadPDFModel = (id) => {
    setCourseCode(id);
    setOpenUploadModel(true);
  };

  const uploadPdf = async (values) => {
    console.log("values", values);
    const pdf = values?.pdf?.[0];
    const body = new FormData();
    body.append("courseCode", courseCode);
    if (pdf !== undefined) body.append("pdf", values.pdf[0]);
    postPDFMutate(body);
  };

  // delete course

  const deleteCourseFunction = async (body) =>
    await axios.post(Config.DeleteCourse, body);

  const deleteCourseSuccess = (data) => {
    console.log("success", data);

    setAlert({
      show: true,
      color: "green",
      msg: data.data.msg,
    });
    clearAlert();
    refetch();
  };

  const deleteCourseError = (data) => {
    console.log("error", data.response);
    setAlert({
      show: true,
      color: "red",
      msg: data.response.data.msg,
    });
    clearAlert();
  };

  const { isLoading: deleteCourseLoading, mutate: deleteCourseMutate } =
    useMutation(deleteCourseFunction, {
      onSuccess: deleteCourseSuccess,
      onError: deleteCourseError,
    });

  //Post PDF
  
  const uploadPdfFunction = async (body) => {
      return await axios.post(Config.UploadPdf, body, {
      headers: {
        authorization: Config.AxiosConfig.headers.authorization,
        "Content-Type": "multipart/form-data; charset=UTF-8",
      },
    });
  };

  const uploadPdfSuccess = (data) => {
    console.log("success", data);
    setOpenUploadModel(false);
    setAlert({
      show: true,
      color: "green",
      msg: data.data.msg,
    });
    clearAlert();
    refetch();
  };

  const uploadPdfError = (data) => {
    console.log("error", data.response);
    setOpenUploadModel(false);
    setAlert({
      show: true,
      color: "red",
      msg: "Error while uploading PDF",
    });
    clearAlert();
  };

  const { isLoading: postPdfLoading, mutate: postPDFMutate } =
    useMutation(uploadPdfFunction, {
      onSuccess: uploadPdfSuccess,
      onError: uploadPdfError,
    });


  return (
    <>
      {openUploadModel && <UploadModel setOpenModel={setOpenUploadModel} uploadPdf={uploadPdf} courseCode={courseCode} postPdfLoading={postPdfLoading} />}
      <Page>
        <BoxContainer>
          <BoxTitle onClick={() => navigate(-1)}>
            <img src={Images.Arrow} alt="arrow" className="w-2" />
            <p className="capitalize">All Courses List</p>
          </BoxTitle>

          <div className="flex justify-end w-full">
            <Link to="/add-course">
              <SmallBtn>Add Course</SmallBtn>
            </Link>
          </div>

          {alert.show && <Alert msg={alert.msg} color={alert.color || "red"} />}

          {!InitialLoading && (
            <TableWrapper>
              <Table
                ApiData={error ? [] : data?.data}
                deleteCourseMutate={deleteCourseMutate}
                openUploadPDFModel={openUploadPDFModel}
                postPdfLoading = {postPdfLoading}
              />
            </TableWrapper>
          )}

          {InitialLoading && <Loading />}
          {error && !InitialLoading && <NotFoundModel />}
        </BoxContainer>
      </Page>
    </>
  );
};

const TableWrapper = tw.div`
my-10`;

const FilterContainer = tw.div`
md:flex space-x-10 items-end mt-8`;

export default AllCoursesList;
