import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Link, useNavigate } from "react-router-dom";
import Images from "../Images";
import { Page, BoxContainer, BoxTitle } from "../Components/Styles/PageStyles";
import { Field, Form, Formik } from "formik";
import {
  InputGroup,
  FieldWrapper,
  Label,
  FormContainer,
  SubmitBtn,
} from "../Components/Styles/InputStyles.jsx";
import DropZone from "../Components/Dropzone";
import Loading from "../Components/Loading";
import { useMutation } from "react-query";
import Config from "../Config";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Alert from "../Components/Alert";
import { Update_User } from "../Redux/actions";

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [alert, setAlert] = useState({ show: false, color: "", msg: "" });
  const clearAlert = () =>
    setTimeout(() => setAlert({ show: false, color: "", msg: "" }), 10000);

  const onSuccess = (data, values) => {
    const user = { title: values.designation, userName: values.fullName };

    dispatch(Update_User(user));

    setAlert({
      msg: "Profile updated successfully",
      show: true,
      color: "green",
    });
    clearAlert();
  };

  const onError = (data) => {
    console.log("error", data);
    setAlert({ msg: "Couldn't update profile", show: true, color: "red" });
    clearAlert();
  };

  const user = useSelector((state) => state.UserReducer.user);

  const postNurseId = async (values) => {
    const image = values?.signature?.[0];

    const body = new FormData();
    body.append("nurseId", user.userId);
    body.append("name", values.fullName);
    body.append("title", values.designation);
    body.append("operation", "update");
    if (image !== undefined) body.append("signature", values.signature[0]);

    await axios.post(Config.ProfileApi, body, {
      headers: {
        authorization: Config.AxiosConfig.headers.authorization,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const { mutate: updateNurseProfile, isLoading } = useMutation(postNurseId, {
    onSuccess,
    onError,
  });

  const initialValues = {
    fullName: user.userName || "",
    designation: user.title || "",
    signature: [],
  };

  const SubmitHandler = async (values) => {
    updateNurseProfile(values);
  };

  return (
    <Page>
      <BoxContainer>
        <BoxTitle onClick={() => navigate(-1)}>
          <img src={Images.Arrow} alt="arrow" className="w-2" />
          <p>My Profile</p>
        </BoxTitle>

        {alert.show && <Alert msg={alert.msg} color={alert.color || "red"} />}

        <div className="mt-10">
          <Formik initialValues={initialValues} onSubmit={SubmitHandler}>
            {(formikProps) => (
              <Form>
                <FormContainer>
                  <InputGroup>
                    <Label htmlFor="fullName">Your Full Name</Label>
                    <FieldWrapper>
                      <Field
                        name="fullName"
                        type="text"
                        id="fullName"
                        placeholder="e.g. Hellan Johnson"
                        required
                      />
                    </FieldWrapper>
                  </InputGroup>

                  <InputGroup>
                    <Label htmlFor="designation">Your Designation</Label>
                    <FieldWrapper>
                      <Field
                        name="designation"
                        type="text"
                        id="designation"
                        placeholder="e.g. Senior Nurse"
                        required
                      />
                    </FieldWrapper>
                  </InputGroup>

                  <InputGroup>
                    <Label htmlFor="signature">Drop your Signature image</Label>

                    <Field name="signature" id="signature" required>
                      {(props) => (
                        <DropZone
                          fields={props.field}
                          setFieldValue={props.form.setFieldValue}
                          componentFor="profile"
                        />
                      )}
                    </Field>
                    <p className="text-xs text-gray-500 mt-2.5 ">
                      Upload Image size (579px by 265px)
                    </p>
                  </InputGroup>
                </FormContainer>
                <SubmitBtnWrapper>
                  <SubmitBtn type="submit" disabled={false}>
                    {isLoading ? (
                      <Loading
                        noPadding={true}
                        color="darkBlue"
                        width={20}
                        height={20}
                      />
                    ) : (
                      "Save Profile"
                    )}
                  </SubmitBtn>
                </SubmitBtnWrapper>
              </Form>
            )}
          </Formik>
        </div>
      </BoxContainer>
    </Page>
  );
};

const SubmitBtnWrapper = tw.div`
mt-10 `;

export default Profile;
