import React, { useEffect, useState } from "react";
import { useTable } from "react-table";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import { Link } from "react-router-dom";
import moment from "moment";

const Table = ({ ApiData }) => {
  const [data, setData] = useState([
    {
      name: "",
      email: "",
      creationDate: "",
      action: "",
      noOfBookings: "",
    },
  ]);

  const ActionBtns = (item) => (
    <ActionBtnWrapper>
      <Link to={`/family-members/${item.custId}`}>
        <AddBtn title="View Family Members">
          <img src={Images.Family} alt="add icon" className="w-3.5" />
        </AddBtn>
      </Link>
    </ActionBtnWrapper>
  );

  const GetBookingTd = (num, bookingId) => {
    return (
      <Link to={`/customer-booking/${bookingId}`}>
        <BookingBtn>{num}</BookingBtn>
      </Link>
    );
  };

  useEffect(() => {
    if (ApiData) {
      const tempData = ApiData.map((item) => ({
        name: item.fullName,
        email: item.email,
        creationDate: moment(item.creationDate).format("DD-MM-YYYY / hh:mm A"),
        action: ActionBtns(item),
        noOfBookings: GetBookingTd(item.noOfBookings, item.custId),
      }));

      setData(tempData);
    }
  }, [ApiData]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Creation Date",
        accessor: "creationDate",
      },
      {
        Header: "No. of Bookings",
        accessor: "noOfBookings",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <CustomTable {...getTableProps()}>
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Th {...column.getHeaderProps()}>{column.render("Header")}</Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>;
              })}
            </Tr>
          );
        })}
      </Tbody>
    </CustomTable>
  );
};

const UserImageWrapper = tw.div` flex items-center space-x-2`;
const UserImage = tw.img`w-5 h-5 rounded-full overflow-hidden`;

const ActionBtnWrapper = tw.div`flex items-center space-x-3`;
const AddBtn = tw.button`grid place-items-center bg-blue-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const EditBtn = tw.button`grid place-items-center bg-yellow-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const CallBtn = tw.button`grid place-items-center bg-green-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;

const CustomTable = tw.table` w-full`;
const Thead = tw.thead`border-b`;
const Tbody = tw.tbody`border-b`;
const Tr = tw.tr`border-b `;
const Th = tw.th`text-left text-sm p-2 font-medium bg-blue-50 text-gray-600`;
const Td = tw.td`p-2 text-xs`;
const BookingBtn = tw.button` px-8 py-1 shadow bg-green-100 text-green-700 rounded cursor-pointer`;

export default Table;
