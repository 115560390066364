import Model from '../Model';
import tw from "tailwind-styled-components";
import DropZone from "../Dropzone";
import { Field, Form, Formik } from "formik";
import {
  InputGroup,
  FieldWrapper,
  Label,
  FormContainer,
  SubmitBtn,
} from "../Styles/InputStyles.jsx";
import Loading from "../Loading";

const initialValues = {
  pdf: [],
};

const openPdf = (mCourseCode) => {
  const link = "http://3.138.198.156/LearningBeam/lb_react/admin/api/upload/pdf/" + mCourseCode + ".pdf";
  const newWindow = window.open(link, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}


const UploadModel = ({ setOpenModel, uploadPdf, courseCode, postPdfLoading }) => {
  return <Model width="w-11/12 max-w-lg" setOpenModel={setOpenModel}>
    <Wrapper>
      <Title>Upload PDF</Title>
      <Formik initialValues={initialValues} onSubmit={uploadPdf}>
        {(formikProps) => (
          <Form>

            <InputGroup>
              <Field name="pdf" id="pdf" required>
                {(props) => (
                  <DropZone
                    fields={props.field}
                    setFieldValue={props.form.setFieldValue}
                    componentFor="profile"
                  />
                )}
              </Field>
              {/* <p className="text-xs text-gray-500 mt-2.5 ">
                     Upload PDF
                    </p> */}
            </InputGroup>
            <div className="w-full grid place-items-center mt-10">
              <SubmitBtn type="submit" disabled={postPdfLoading || (formikProps.values.pdf ? false : true)}>
                {(!postPdfLoading) && "Upload PDF"}
                {(postPdfLoading ) && (
                  <Loading
                    color="brown"
                    width={20}
                    height={20}
                    noPadding={true}
                  />
                )}
              </SubmitBtn>
            </div>
            {/* <Button>
              <SubmitBtn type="submit" disabled={formikProps.values.pdf ? false : true}>
                Upload PDF
                  </SubmitBtn>
            </Button> */}
          </Form>
        )}
      </Formik>
      {/* <Para onClick={() => openPdf(courseCode)}>Previous Uploaded PDF</Para> */}
    </Wrapper>
  </Model>;
}

const Wrapper = tw.div`w-11/12 max-w-3xl mx-auto my-5  flex flex-col  `;
const Title = tw.p`text-base md:text-lg mb-5 text-black-400 font-medium md:leading-relaxed text-center`;
const Para = tw.p`text-base md:text-sm text-neutral-800 mt-5 mb-5 font-small text-center cursor-pointer `;
const Button = tw.button`text-sm mt-5 w-32 mx-auto md:w-44 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-red-100 hover:bg-red-200 text-red-800 rounded-md shadow`;
export default UploadModel;