import Model from '../Model';
import tw from "tailwind-styled-components";

const DescriptionModel = ({ setOpenModel, desc }) => {
    return <Model width="w-11/12 max-w-lg" setOpenModel={setOpenModel}>
        <Wrapper>
            <Title>Student Query Description</Title>
            <Para>{ desc}</Para>
        </Wrapper>
    </Model>;
}

const Wrapper = tw.div`w-11/12 max-w-3xl mx-auto my-5  flex flex-col  `;
const Title = tw.p`text-base md:text-lg text-black-400 font-medium md:leading-relaxed text-center`;
const Para = tw.p`text-base md:text-m text-black-400 mt-5 font-normal md:leading-relaxed text-center`;

export default DescriptionModel;