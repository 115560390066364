import React from "react";
import tw from "tailwind-styled-components";
import BookingSummaryComp from "../Components/Dashboard/BookingSummaryComp";
import RecentBookingComp from "../Components/Dashboard/RecentBookingComp";
import { useQuery } from "react-query";
import axios from "axios";
import Config from "../Config";
import Loading from "../Components/Loading";
import { Page, ErrorText } from "../Components/Styles/PageStyles";
import { Save_Device_types } from "../Redux/actions";
import { useDispatch } from "react-redux";
import ErrorModel from "../Components/ErrorModel";

const DashBoard = () => {
  const dispatch = useDispatch();

  const fetchFunction = async () => await axios.get(Config.GetDashboardData);

  const refetchInterval = 1000 * 60 * 10; //10 minutes

  const { isLoading, error, data } = useQuery("GetDashboardData", fetchFunction, {
    refetchInterval,
  });

  // const data = [];
  // const isLoading = false;
  // const error = "";

  console.log("data", data);

  return (
    <Page>
      <Container>
        {isLoading && <Loading/>}
        {error && <ErrorModel />}

        {!isLoading && !error && (
          <>
            <BookingSummaryComp data={data} />
            <RecentBookingComp data={data?.data} />
          </>
        )}
      </Container>
    </Page>
  );
};

const Container = tw.div`Container`;

export default DashBoard;
