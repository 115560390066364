const Images = {
  LoginImage: "./images/login-image2.jpeg",
  Logo: "./images/logo.svg",
  MicrosoftLogo: "./images/microsoft.svg",
  NurseTextOverlay: "./images/nurse-text-overlay.png",
  SearchIcon: "./images/search-icon.svg",
  SearchBlueIcon: "./images/search-blue.svg",
  Arrow: "./images/arrow.svg",
  userImage: "./images/user.png",
  UserImage: "./images/logo.svg",
  Add: "./images/add.svg",
  Join: "./images/join-meeting-2.svg",
  List: "./images/list-icon.svg",
  Eye: "./images/eye.svg",
  Edit: "./images/edit.svg",
  Call: "./images/call.svg",
  Time: "./images/time.svg",
  Cross: "./images/cross.svg",
  CrossBlack: "./images/cross-black.svg",
  Calender: "./images/calender.svg",
  Email: "./images/email.svg",
  Upload: "./images/upload-outline.svg",
  ErrorImage: "./images/error-image.svg",
  SearchImage: "./images/search-image.svg",
  NotFound: "./images/no-record-found.svg",
  Send: "./images/send.svg",
  Reminder: "./images/reminder.svg",
  Family: "./images/family.svg",
  Filter: "./images/filter.svg",
  Excel: "./sample-sheet.xlsx",
  Delete: "./images/delete.svg",
};

export default Images;
