import React from "react";
import {
  Page,
  BoxContainer,
  BoxTitle,
  ErrorText,
} from "../Components/Styles/PageStyles";
import tw from "tailwind-styled-components";
import { Link } from "react-router-dom";
import Images from "../Images";

const Help = () => {
  return (
    <Page>
      <BoxContainer>
        <Link to="/">
          <BoxTitle>
            <img src={Images.Arrow} alt="arrow" className="w-2" />
            <p>Need Help?</p>
          </BoxTitle>
        </Link>
      </BoxContainer>
    </Page>
  );
};

export default Help;
