import React, { useEffect } from "react";
import Images from "../Images";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import tw from "tailwind-styled-components";
import Config from "../Config";
import axios from "axios";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Save_User } from "../Redux/actions";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, []);

  const handleLogin = () => {
    instance
      .loginPopup(loginRequest)
      .then(async function (loginResponse) {
        let accountId = loginResponse.account.homeAccountId;

        // const requestOptions = {
        //   method: "POST",
        //   headers: { "Content-Type": "application/json" },
        //   body: JSON.stringify({
        //     nurseId: loginResponse.uniqueId,
        //     name: loginResponse.account.name,
        //     signature: "",
        //     title: "",
        //     operation: "login",
        //   }),
        // };
        // const res = await axios.post(Config.ProfileApi, requestOptions.body, {
        //   headers: requestOptions.headers,
        // });

        const userData = {
          userId: loginResponse.uniqueId,
          userName: loginResponse.account.name,
          expiresOn: moment(loginResponse.expiresOn).format("DD-MM-YYYY"),
          title: "",
          userImage: "",
        };

        dispatch(Save_User(userData));

        navigate("/");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // const saveToLocalStorage = (userObj) =>
  //   localStorage.setItem("user", JSON.stringify(userObj));

  return (
    <Login>
      <TextWrapperComp handleLogin={handleLogin} />

      <ImageWrapper>
        <LoginImage src={Images.LoginImage} alt="Learning Beam" />
      </ImageWrapper>
    </Login>
  );
};

const TextWrapperComp = ({ handleLogin }) => (
  <TextWrapper>
    <Container>
      {/* <TextOverlay>
        <img
          src={Images.NurseTextOverlay}
          alt="text-overlay"
          className="w-full h-full object-contain"
        />
      </TextOverlay> */}

      <Logo src={Images.Logo} alt="learningbeam logo" />

      <Center>
        <Title>Welcome Back!</Title>
        <SubTitle>Please Login to your account.</SubTitle>
        <Button onClick={() => handleLogin()}>
          <img
            className="w-5"
            src={Images.MicrosoftLogo}
            alt="microsoft logo"
          />
          <p> Log In with Microsoft</p>
        </Button>
      </Center>

      <BottomText>
		Ontario's best online schooling portal
      </BottomText>
    </Container>
  </TextWrapper>
);

const Login = tw.section`h-screen overflow-hidden  md:flex  `;
const Logo = tw.img`w-24 md:w-24 mx-auto mt-10 md:mx-0`;
const Container = tw.div`Container flex flex-col `;
const TextWrapper = tw.div`w-full h-full  md:w-1/2 relative md:overflow-hidden md:pl-7`;
const TextOverlay = tw.section`absolute -right-40 top-0 h-full  `;

const Center = tw.div`grow  pt-24 flex flex-col items-center md:items-start`;

const Title = tw.h1`text-xl sm:text-2xl font-light text-gray-600`;

const SubTitle = tw.h3`mt-1 sm:mt-2 font-light  text-gray-400 text-sm  `;

const Button = tw.button` mt-5 sm:mt-8 lg:mt-10 text-gray-500 flex items-center space-x-2 px-12 sm:px-16 md:px-12 lg:px-16 py-3 lg:py-4 xl:px-20 border rounded-md bg-white z-10 shadow-md hover:bg-gray-50 hover:shadow-none transition duration-200`;

const BottomText = tw.p`mb-10 text-xs text-gray-400  `;

const ImageWrapper = tw.div`hidden h-full  md:block md:w-1/2 lg:w-4/5 `;
const LoginImage = tw.img`h-full w-full object-cover`;

export default LoginPage;
