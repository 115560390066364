import React from "react";
import tw from "tailwind-styled-components";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { hasPrivateAccess } from "../Config";

const NavFloatingMenu = ({ setIsUserMenuVisible, logout }) => {
  const user = useSelector((state) => state.UserReducer.user);

  return (
    <Wrapper>
      <Triangle></Triangle>

      <Greeting>Hi {user?.userName || "User"}</Greeting>
      <MenuWrapper>
        <MenuLinks setIsUserMenuVisible={setIsUserMenuVisible} user={user} />

        <Logout onClick={logout}>Logout</Logout>
      </MenuWrapper>
    </Wrapper>
  );
};

const MenuLinks = ({ setIsUserMenuVisible, user }) => (
  <>
    {/* <Link to="/my-profile">
      <MenuItem>My Profile</MenuItem>
    </Link> */}
    {hasPrivateAccess(user?.userId) && (
      <Link to="/admin">
        <MenuItem>Admin</MenuItem>
      </Link>
    )}{" "}
  </>
);

const Wrapper = tw.div`
p-6  rounded-md bg-white border-2 border-gray-100 w-56  absolute top-12 right-0 shadow-lg z-10 lg:top-14 lg:w-64`;

const Triangle = tw.div`
w-5 h-5 bg-white absolute -top-3 right-3 transform rotate-45  border-l-2 border-t-2 border-gray-100`;

const Greeting = tw.h2`
text-gray-800 text-sm md:text-base mb-5 font-normal truncate`;

const MenuWrapper = tw.div`
flex flex-col gap-2 w-full`;

const MenuItem = tw.p`
${(p) => p.$extraClass}
text-sm w-full relative text-gray-400  hover:text-gray-600 border-b border-gray-100 pb-2 hover:text-custom-green  cursor-pointer `;

const Logout = tw.p` 
text-red-500 text-sm cursor-pointer`;

export default NavFloatingMenu;
