import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { Link, useParams, useNavigate } from "react-router-dom";
import Images from "../Images";
import Table from "../Components/BookingList/Table";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Config from "../Config";
import Loading from "../Components/Loading";
import AdminTable from "../Components/Admin/AdminTable";
import {
  Page,
  BoxContainer,
  BoxTitle,
  ErrorText,
} from "../Components/Styles/PageStyles";
import NotFoundModel from "../Components/NotFoundModel";
import { SmallBtn } from "../Components/Styles/InputStyles";
import UploadModel from "../Components/Admin/UploadModel";
import Alert from "../Components/Alert";
import { useSelector } from "react-redux";
import { hasPrivateAccess } from "../Config";

const Admin = () => {
  const [alert, setAlert] = useState({ show: false, color: "", msg: "" });

  const user = useSelector((state) => state.UserReducer.user);

  const navigate = useNavigate();

  useEffect(() => {
    if (!hasPrivateAccess(user?.userId)) navigate("/");
  }, [user]);

  const clearAlert = () =>
    setTimeout(() => setAlert({ show: false, color: "", msg: "" }), 10000);

  //Getting Initial Data
  const fetchFunction = async () =>
    await axios.get(Config.AdminApi, Config.AxiosConfig);

  const [openUploadModel, setOpenUploadModel] = useState(false);

  const { isLoading, error, data, refetch, isRefetching } = useQuery(
    "adminApi",
    fetchFunction
  );

  // Upload Excel File
  const postFunction = async (file) => {
    if (file !== null && file !== undefined) {
      const body = new FormData();
      body.append("file", file[0]);

      return await axios.post(Config.AdminUploadApi, body, {
        headers: {
          authorization: Config.AxiosConfig.headers.authorization,
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      });
    } else throw Error("error");
  };

  const onSuccess = (data) => {
    refetch();
    setOpenUploadModel(false);
    setAlert({ msg: "File Uploaded Successfully", show: true, color: "green" });
    clearAlert();
  };

  const onError = (data) => {
    setAlert({ msg: "Couldn't upload File", show: true, color: "red" });
    setOpenUploadModel(false);
    clearAlert();
  };

  const { isLoading: uploadLoading, mutate: uploadExcelFile } = useMutation(
    postFunction,
    { onSuccess, onError }
  );

  // Delete device
  const deleteDeviceHandler = async (id) =>
    await axios.post(
      Config.DeleteDeviceApi,
      { deviceId: id, status: 0 },
      Config.AxiosConfig
    );

  const onDeleteDeviceSuccess = (data) => {
    refetch();
    setAlert({
      msg: "Device Deleted Successfully",
      show: true,
      color: "green",
    });
    clearAlert();
  };

  const onDeleteDeviceError = (data) => {
    setAlert({ msg: "Couldn't Delete Device", show: true, color: "red" });
    clearAlert();
  };

  const { isLoading: deleteDeviceLoading, mutate: deleteDeviceMutate } =
    useMutation(deleteDeviceHandler, {
      onSuccess: onDeleteDeviceSuccess,
      onError: onDeleteDeviceError,
    });

  if (!hasPrivateAccess(user?.userId)) {
    return null;
  }

  return (
    <Page>
      <BoxContainer>
        <Link to="/">
          <BoxTitle>
            <img src={Images.Arrow} alt="arrow" className="w-2" />
            <p>Admin</p>
          </BoxTitle>
        </Link>

        {alert.show && <Alert msg={alert.msg} color={alert.color || "red"} />}

        <Uploader setOpenUploadModel={setOpenUploadModel} />

        {openUploadModel && (
          <UploadModel
            uploadLoading={uploadLoading}
            setOpenUploadModel={setOpenUploadModel}
            uploadExcelFile={uploadExcelFile}
          />
        )}

        {!isLoading && !isRefetching && (
          <TableWrapper>
            <AdminTable
              ApiData={error ? [] : data?.data}
              deleteDeviceMutate={deleteDeviceMutate}
            />
          </TableWrapper>
        )}

        {(isLoading || isRefetching) && <Loading />}
        {error && !isLoading && <NotFoundModel />}
      </BoxContainer>
    </Page>
  );
};

const Uploader = ({ setOpenUploadModel }) => {
  return (
    <UploadWrapper>
      <SmallBtn onClick={() => setOpenUploadModel(true)}>
        Upload Excel File
      </SmallBtn>
    </UploadWrapper>
  );
};

const TableWrapper = tw.div`
my-7`;

const UploadWrapper = tw.div`
 w-full flex justify-end `;

export default Admin;
