import React, { useEffect, useState } from "react";
import { useTable, useSortBy } from "react-table";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import DeleteModel from './DeleteModel';

const Table = ({ ApiData, deleteCourseMutate, postPDFMutate, openUploadPDFModel }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState([
    {
      name: "",
      email: "",
      sessDuration: "",
      startTime: "",
      sessionDate: "",
      action: "",
    },
  ]);

  const [courseId, setCourseId] = useState(false);
  const [courseCode, setCourseCode] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [openUploadModel, setOpenUploadModel] = useState(false);


  const openDeleteModel = (id) => {
    setCourseId(id);
    setOpenModel(true);
  };

  const deleteCourse = () => {
    const body = new FormData();
    body.append("id", courseId);
    deleteCourseMutate(body);
    setOpenModel(false);
  };

  const goToEditCourse = (id) => navigate(`/edit-course/${id}`);
  const goToAddCourseDetails = (id) => navigate(`/add-details/${id}`);
  const goToAddChapters = (id) => navigate(`/add-chapters/${id}`);

  const ActionBtns = (item) => {
    return (
      <ActionBtnWrapper>
        <EditBtn title="Edit Course" onClick={() => goToEditCourse(item.id)}>
          <img src={Images.Edit} alt="edit icon" />
        </EditBtn>

        <CreateBtn title="Add Details" onClick={() => goToAddCourseDetails(item.courseCode)}>
          <img src={Images.Add} alt="Add Details" />
        </CreateBtn>

        <AddBtn title="Add Chapters" onClick={() => goToAddChapters(item.courseCode)}>
          <img src={Images.List} alt="Add Chapters" />
        </AddBtn>

        <UploadBtn title="Upload PDF" onClick={() => openUploadPDFModel(item.courseCode)}>
          <img src={Images.Upload} alt="Upload PDF" />
        </UploadBtn>

        <DeleteBtn
          title="Delete Course icon"
          onClick={() => openDeleteModel(item.id)}
        >
          <img src={Images.Delete} alt="Delete Course icon" className="w-3.5" />
        </DeleteBtn>
      </ActionBtnWrapper>
    );
  };

  useEffect(() => {
    if (ApiData) {
      const tempData = ApiData.map((item) => ({
        courseCode: item.courseCode,
        grade: item.grade,
        courseName: <p clasName="whitespace-normal break-all">{item.courseName}</p>,
        creationDate: item.creationDate,
        price: item.fee,
        action: ActionBtns(item),
      }));

      setData(tempData);
    }
  }, [ApiData]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Grade",
        accessor: "grade",
      },
      {
        Header: "Course Name",
        accessor: "courseName",
      },
      {
        Header: "Course Code",
        accessor: "courseCode",
      },
      {
        Header: "Fee",
        accessor: "price",
      },

      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        data,
        columns,
      },
      useSortBy
    );

  return (
    <>
      {openModel && <DeleteModel setOpenModel={setOpenModel} deleteCourse={deleteCourse} />}
      
      <CustomTable {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}

                  <span>
                    {column.isSorted ? (
                      <img
                        src={Images.Arrow}
                        alt="down arrow"
                        className={`${
                          column.isSortedDesc ? "-rotate-90" : "rotate-90"
                          } w-1.5 inline-block ml-1.5`}
                      />
                    ) : (
                        ""
                      )}
                  </span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </CustomTable>
    </>
  );
};

const UserImageWrapper = tw.div` flex items-center space-x-2`;
const UserImage = tw.img`w-5 h-5 rounded-full overflow-hidden`;

const ActionBtnWrapper = tw.div`flex items-center space-x-3`;
const AddBtn = tw.button`grid place-items-center bg-blue-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const CreateBtn = tw.button`grid place-items-center bg-yellow-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const Btn = tw.button`grid place-items-center bg-blue-100 p-2 rounded-md  transform transition duration-200 hover:scale-110 hover:shadow-md`;
const EditBtn = tw.button`grid place-items-center bg-yellow-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const UploadBtn = tw.button`grid place-items-center bg-green-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const DeleteBtn = tw.button`grid place-items-center bg-red-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;

const ViewReports = tw.button`grid place-items-center bg-orange-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;

const CustomTable = tw.table` w-full`;
const Thead = tw.thead`border-b`;
const Tbody = tw.tbody`border-b`;
const Tr = tw.tr`border-b `;
const Th = tw.th`text-left text-sm p-2 font-medium bg-blue-50 text-gray-600`;
const Td = tw.td`p-2 text-xs`;

export default Table;
