import tw from "tailwind-styled-components";

export const InputGroup = tw.div` w-full flex flex-col space-y-1.5`;

export const HorizontalInputGroup = tw.div` w-full horizontal-input-group my-8`;

export const CombineInputGroup = tw.div`w-full flex flex-row space-x-4`;

export const FieldWrapper = tw.div`
${(p) => (p.$select ? "" : "pl-2 field-wrapper border border-gray-400")}
 relative  rounded bg-gray-50 w-full  flex items-center`;

export const Label = tw.label`text-sm text-gray-500 font-normal`;

export const FormContainer = tw.div` grid sm:grid-cols-2 gap-5 md:gap-10 mt-4`;

export const SubmitBtn = tw.button`
text-sm  w-32  md:w-44 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-blue-100 hover:bg-blue-200 text-blue-800 rounded-md shadow`;

export const PreviewBtn = tw.button`
  text-sm  w-32  md:w-44 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-yellow-100 hover:bg-yellow-200 text-yellow-800 rounded-md shadow`;

export const SmallBtn = tw.button`
  whitespace-nowrap px-6 py-3 rounded bg-green-100  cursor-pointer hover:bg-green-200 hover:shadow-md transition duration-200 text-green-700 text-sm`;
