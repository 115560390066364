import React, { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import { Link, useNavigate } from "react-router-dom";
import Images from "../Images";
import Table from "../Components/StudentsList/Table";
import { useQuery } from "react-query";
import axios from "axios";
import Config from "../Config";
import Loading from "../Components/Loading";
import { useMutation } from "react-query";
import Alert from "../Components/Alert";
import {
  Page,
  BoxContainer,
  BoxTitle,
  ErrorText,
} from "../Components/Styles/PageStyles";
import NotFoundModel from "../Components/NotFoundModel";
import { Formik, Form, Field } from "formik";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SmallBtn,
} from "../Components/Styles/InputStyles";
import moment from "moment";

const StudentList = () => {
  const navigate = useNavigate();

  const [alert, setAlert] = useState({ show: false, color: "", msg: "" });

  const clearAlert = () =>
    setTimeout(() => setAlert({ show: false, color: "", msg: "" }), 10000);

  const fetchFunction = async () => await axios.get(Config.GetStudentQuery);

  const {
    isLoading: InitialLoading,
    error,
    data,
  } = useQuery("GetStudentQuery", fetchFunction);

  console.log("data", data);

  return (
    <Page>
      <BoxContainer>
        <BoxTitle onClick={() => navigate(-1)}>
          <img src={Images.Arrow} alt="arrow" className="w-2" />
          <p className="capitalize">Students Queries</p>
        </BoxTitle>

        {/* <div className="flex justify-end w-full">
          <Link to="/add-course">
            <SmallBtn>Add Course</SmallBtn>
          </Link>
        </div> */}

        {alert.show && <Alert msg={alert.msg} color={alert.color || "red"} />}

        {!InitialLoading && (
          <TableWrapper>
            <Table
              ApiData={error ? [] : data?.data}
              // sendEmailReminder={sendEmailReminder}
            />
          </TableWrapper>
        )}

        {InitialLoading && <Loading />}
        {error && !InitialLoading && <NotFoundModel />}
      </BoxContainer>
    </Page>
  );
};

const TableWrapper = tw.div`
my-10`;

const FilterContainer = tw.div`
md:flex space-x-10 items-end mt-8`;

export default StudentList;
