import Model from '../Model';
import tw from "tailwind-styled-components";

const DeleteModel = ({ setOpenModel, deleteCourse }) => {
    return <Model width="w-11/12 max-w-lg" setOpenModel={setOpenModel}>
        <Wrapper>
            <Title>Delete Course</Title>
            <Para>This course will be deleted and this cannot be undone. Are you sure to delete this course</Para>
        <Button onClick={deleteCourse} >
              Delete Course
            </Button>
        </Wrapper>
    </Model>;
}

const Wrapper = tw.div`w-11/12 max-w-3xl mx-auto my-5  flex flex-col  `;
const Title = tw.p`text-base md:text-lg text-black-400 font-medium md:leading-relaxed text-center`;
const Para = tw.p`text-base md:text-m text-black-400 mt-5 mb-5 font-normal md:leading-relaxed text-center`;
const Button = tw.button`text-sm  w-32 mx-auto md:w-44 h-12 grid place-items-center disabled:opacity-50 disabled:cursor-not-allowed   md:text-base bg-red-100 hover:bg-red-200 text-red-800 rounded-md shadow`;

export default DeleteModel;