import Navbar from "./Components/Navbar";
import DashBoard from "./Pages/DashBoard";
import LoginPage from "./Pages/LoginPage";
import { Route, Routes } from "react-router-dom";
import BookingList from "./Pages/BookingList";
import AllCoursesList from "./Pages/AllCoursesList";
import StudentsList from "./Pages/StudentsList";
import Profile from "./Pages/Profile";
import Search from "./Pages/Search";
import { useSelector } from "react-redux";
import Help from "./Pages/Help";
import Admin from "./Pages/Admin";
import AddCourse from "./Pages/AddCourse";
import EditCourse from "./Pages/EditCourse";
import AddCouseDetails from "./Pages/AddCourseDetails";
import AddChapters from "./Pages/AddChapters";

function App() {
  const user = useSelector((state) => state.UserReducer.user);

  if (!user) return <LoginPage />;

  return (
    <>
      <Navbar />

      <Routes>
        <Route path="/" element={<DashBoard />} />
        <Route path="/help" element={<Help />} />
        <Route path="/add-course" element={<AddCourse />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/all-courses" element={<AllCoursesList />} />
        <Route path="/students-queries" element={<StudentsList />} />
        <Route path="/edit-course/:id" element={<EditCourse />} />
        <Route path="/add-details/:id" element={<AddCouseDetails />} />
        <Route path="/add-chapters/:id" element={<AddChapters />} />
        <Route path="/bookings" element={<BookingList />}>
          <Route path=":bookingType" element={<BookingList />} />
        </Route>
        <Route path="/my-profile" element={<Profile />} />
        <Route path="/search" element={<Search />} />
      </Routes>
    </>
  );
}

export default App;
