import React, { useState } from "react";
import tw from "tailwind-styled-components";
import Table from "./Table";
import Config from "../../Config";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Alert from "../Alert";

const RecentBookingComp = ({ data }) => {
  const [alert, setAlert] = useState({ show: false, color: "", msg: "" });

  const clearAlert = () =>
    setTimeout(() => setAlert({ show: false, color: "", msg: "" }), 10000);

  const PostFunction = async (bookingId) => {
    return await axios.post(
      Config.EmailReminderApi,
      { bookingId },
      Config.AxiosConfig
    );
  };

  const onSuccess = (data) => {
    window.scrollTo({ top: 350, left: 0 });
    setAlert({ msg: "Reminder Sent Successfully", show: true, color: "green" });
    clearAlert();
  };
  const onError = (data) => {
    console.log("error", data);
    setAlert({ msg: "Couldn't Sent Reminder", show: true, color: "red" });
    clearAlert();
  };

  const { isLoading, mutate: sendEmailReminder } = useMutation(PostFunction, {
    onSuccess,
    onError,
  });

  return (
    <RecentBooking>
      <Title>Recent Student's Queries</Title>

      {alert.show && <Alert msg={alert.msg} color={alert.color || "red"} />}

      <TableWrapper>
        <Table ApiData={data} //sendEmailReminder={sendEmailReminder}
        />
      </TableWrapper>
    </RecentBooking>
  );
};

const RecentBooking = tw.div` mt-10 md:border md:rounded-md md:shadow-md md:p-8 lg:p-10 `;
const Title = tw.h1`text-xl  text-gray-700 font-medium`;
const TableWrapper = tw.h1`mt-5`;

export default RecentBookingComp;
